<script lang="ts" setup>
withDefaults(
	defineProps<{
		isSvg?: boolean;
		locationTid?: string | boolean | null;
	}>(),
	{ isSvg: false, locationTid: null }
);

const inputValue = ref("");
const passwordHidden = ref(true);
const iconName = computed(() => (passwordHidden.value ? "visible" : "invisible"));
</script>

<template>
	<MInput
		v-model="inputValue"
		:location-tid="locationTid"
		:type="passwordHidden ? 'password' : 'text'"
		:class="['password-input', passwordHidden ? undefined : 'show']"
	>
		<template #afterInput>
			<AIconButton
				tabindex="-1"
				variant="ghost"
				data-tid="show-password"
				@click.prevent="passwordHidden = !passwordHidden"
			>
				<ASvg v-if="isSvg" class="password-icon" :name="`20/eye-${iconName}`" />
				<NuxtIcon v-else class="password-icon" :name="`20/eye-${iconName}`" filled />
			</AIconButton>
		</template>
		<slot />
	</MInput>
</template>

<style>
:root {
	--m-password-input-icon: var(--changde);
	--m-password-input-icon-size: 24px;
	--m-password-input-icon-stroke: var(--conakry);
	--m-password-input-icon-fill: transparent;
}

.tao:root {
	--m-password-input-icon: var(--gray-400);
	--m-password-input-icon-stroke: var(--gray-400);
}

.nlc:root {
	--m-password-input-icon: var(--caracas);
	--m-password-input-icon-fill: transparent;
}

.fortuneWheelz:root {
	--m-password-input-icon: var(--chennai);
	--m-password-input-icon-stroke: var(--chennai);
}
</style>

<style scoped lang="scss">
.password-input {
	--eye-background: 0;
	--eye-offset: 3px;
	--eye-wrapper-y: 0;
	--eye-y: 0;
	--eye-x: 0;
	--eye-s: 1;

	button {
		padding: 0;
		margin: gutter(0.5) gutter(1) gutter(0.5) 0;
		display: inline-flex;
		align-items: center;

		&:hover {
			background: transparent;
		}
	}
}

.password-icon {
	margin-right: gutter(2);
	font-size: var(--m-password-input-icon-size);
	cursor: pointer;
	color: var(--m-password-input-icon);
	&:deep(svg) {
		path {
			// stroke: var(--m-password-input-icon-stroke);
			// fill: var(--m-password-input-icon-fill);
		}
	}
}
</style>
